import { createTheme } from '@mui/material/styles';


// A custom theme for this app
const theme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#f57c00',
    },
    secondary: {
      main: '#6d4c41',
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1920,
    },
  },
  typography: {
    h1: {
      fontFamily: "Montserrat",
      fontWeight: 600,
      fontSize: '1.8rem'
    },
    p: {
      fontFamily: "Montserrat",
      marginBottom: "1.5rem",
      lineHeight: "1.7"
    }
  }
});

/*
    xl: "2rem",
    lg: "2rem",
    md: "1.8rem",
    sm: "1.5rem",
    xs: "0.7rem",
*/
theme.typography.h1 = {
  fontFamily: "Montserrat",
  fontWeight: 600,
  marginBottom: "2rem",
  marginTop: "0.5rem",
  color: "#a84411",
  [theme.breakpoints.up('xs')]: {
    fontSize: '2rem',
  },
  [theme.breakpoints.up('sm')]: {
    fontSize: '2rem',
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '2.5rem',
  },
  [theme.breakpoints.up('lg')]: {
    fontSize: '2.5rem',
  },
}

theme.typography.h2 = {
  fontFamily: "Montserrat",
  fontWeight: 600,
  marginBottom: "1.2rem",
  marginTop: "1.5rem",
  color: "#ab7c64",
  [theme.breakpoints.up('xs')]: {
    fontSize: '1.5rem',
  },
  [theme.breakpoints.up('sm')]: {
    fontSize: '1.8rem',
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '1.8rem',
  },
  [theme.breakpoints.up('lg')]: {
    fontSize: '1.8rem',
  },
}

theme.typography.h3 = {
  fontFamily: "Montserrat",
  fontWeight: 600,
  marginBottom: "1.2rem",
  marginTop: "1.5rem",
  color: "#ab7c64",
  [theme.breakpoints.up('xs')]: {
    fontSize: '1.4rem',
  },
  [theme.breakpoints.up('sm')]: {
    fontSize: '1.5rem',
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '1.8rem',
  },
  [theme.breakpoints.up('lg')]: {
    fontSize: '1.8rem',
  },
}

theme.typography.h4 = {
  fontFamily: "Montserrat",
  fontWeight: 600,
  marginBottom: "1.2rem",
  color: "#c75400",
  [theme.breakpoints.up('xs')]: {
    fontSize: '1.2rem',
  },
  [theme.breakpoints.up('sm')]: {
    fontSize: '1.3rem',
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '1.3rem',
  },
  [theme.breakpoints.up('lg')]: {
    fontSize: '1.4rem',
  },
}
/*
  default: {
    xl: "1.8rem",
    lg: "1.5rem",
    md: "1.5rem",
    sm: "1.3rem",
    xs: "0.5rem",
  },
*/
const paragraph = {
  fontFamily: "Montserrat",
  marginBottom: "1rem",
  marginTop: "0.5rem",
  lineHeight: "1.7",
  [theme.breakpoints.up('xs')]: {
    fontSize: '1.2rem',
    marginBottom: "0.8rem",
    marginTop: "0.5rem",  
  },
  [theme.breakpoints.up('sm')]: {
    fontSize: '1.3rem',
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '1.5rem',
  },
  [theme.breakpoints.up('lg')]: {
    fontSize: '1.5rem',
  },
  [theme.breakpoints.up('xl')]: {
    fontSize: '1.8rem',
  },
}
const paragraphSmall = {
  fontFamily: "Montserrat",
  marginBottom: "1rem",
  marginTop: "0.5rem",
  [theme.breakpoints.up('xs')]: {
    fontSize: '0.6rem',
    marginBottom: "0.5rem",
    marginTop: "0.5rem",  
  },
  [theme.breakpoints.up('sm')]: {
    fontSize: '0.5rem',
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '1.0rem',
  },
  [theme.breakpoints.up('lg')]: {
    fontSize: '1.0rem',
  },
}
theme.typography.body1 = paragraph;
theme.typography.body2 = paragraphSmall;
theme.typography.inherit = paragraph;

export default theme;
